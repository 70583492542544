import React from "react";
import { useStyles } from "./style";
import { MissionIcon, VisionIcon } from "../../../assets/icons";

const Who = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="who">
          <div
            className="who__left desktop"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1400"
          >
            <div className="who__left__img_container ">
              <div className="who__left__img"></div>
            </div>
            <div className="who__left__shadow"></div>
          </div>
          <div className="who__left mobile">
            <div className="who__left__img_container ">
              <div className="who__left__img"></div>
            </div>
            <div className="who__left__shadow"></div>
          </div>
          <div className="who__right">
            <h6
              className="who__left"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1400"
            >
              Who We Are
            </h6>
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              A digital-first savings and payment platform committed to providing ethical and sustainable financial solutions at ease.
            </p>
            <p
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1700"
            >
              We believe in empowering individuals to achieve their financial goals while making a positive impact on society through transparency, community building, focus on core product offerings, minimalist tech features, efficient and personalized user support. We want to simplify your journey to financial freedom.
            </p>
            
          </div>
        </div>
        <div className="how_container">
        <div className="how">
          <div>
            <h4
              data-aos="fade-down-right"
              data-aos-easing="linear"
              data-aos-duration="1400"
            >
              How we Intend to Change Banking for all of Us!
            </h4>
            <p
              className="intro desktop"
              data-aos="fade-down-left"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              By bringing non-interest banking and other Islamic banking tools
              for the benefit of all.
            </p>
            <p
              className="intro mobile"
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              By bringing non-interest banking and other Islamic banking tools
              for the benefit of all.
            </p>
          </div>
          <div
            className="how__card desktop"
            data-aos="fade-down-left"
            data-aos-easing="linear"
            data-aos-duration="1600"
          >
            <div className="row">
              <VisionIcon /> <h6>Our Vision</h6>
            </div>
            “For all of us”. Bringing the benefits of non-interest banking to
            everyone.
          </div>
          <div
            className="how__card mobile"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1600"
          >
            <div className="row">
              <VisionIcon /> <h6>Our Vision</h6>
            </div>
            “For all of us”. Bringing the benefits of non-interest banking to
            everyone.
          </div>
          <div
            className="how__card desktop"
            data-aos="fade-down-left"
            data-aos-easing="linear"
            data-aos-duration="1700"
          >
            <div className="row">
              <MissionIcon /> <h6>Our Mission</h6>
            </div>
            To bring the opportunities of non-interest banking to everyone. In
            an easy, exciting and secured way. While leveraging technology.
          </div>
          <div
            className="how__card mobile"
            data-aos="fade-in"
            data-aos-easing="linear"
            data-aos-duration="1700"
          >
            <div className="row">
              <MissionIcon /> <h6>Our Mission</h6>
            </div>
            To bring the opportunities of non-interest banking to everyone. In
            an easy, exciting and secured way. While leveraging technology.
          </div>
        </div>
        <div className="values">
        <h4
              data-aos="fade-down-right"
              data-aos-easing="linear"
              data-aos-duration="1400"
            >
              Our Values
            </h4>
            <div className="values__container">
            <div className="values__left">
              <h6>Simplicity</h6>
              <p>We believe that non-interest banking should be made easy to access and understand.</p>
              <hr/>
              <h6>Transparency</h6>
              <p>We ensure transparency and uphold the highest standards of honesty for our stakeholders.</p>
              <hr/>
              <h6>Sustainability</h6>
              <p>We prioritize environmental and social responsibility.</p>
              <hr/>
              <h6>Innovation</h6>
              <p>We embrace technology to deliver cutting-edge financial solutions to all users.</p>
              <hr/>
              <h6>Customer Focus</h6>
              <p>We are dedicated to providing exceptional customer service.</p>
              <hr/>
              <h6>Excellence</h6>
              <p>We are outstanding, polite, humble, respectful and family friendly in all we do.</p>
              
            </div>
            <div className="values__right"></div>
            </div>
        </div>

        </div>
      </div>
    </div>
  );
};

export default Who;
